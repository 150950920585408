import { apiResponseValidator } from "../api-response-validator";

export const getProductCategories = async () => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/products/categories",
      // "https://9ydsb15ld4.execute-api.eu-central-1.amazonaws.com/products/categories",
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    const responseValidation = apiResponseValidator(response);
    if (!responseValidation.success) return responseValidation;

    const data = await response.json();
    if (data && data.Items && data.Items.length) {
      return { success: true, productCategories: data.Items };
    }
    return { success: false };
  } catch (error) {
    return {
      success: false,
      message: error.message ?? "network-products-getproductcategories-fail",
    };
  }
};

export const getProducts = async () => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/products/catalogue",
      // "https://9ydsb15ld4.execute-api.eu-central-1.amazonaws.com/products/catalogue",
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    const responseValidation = apiResponseValidator(response);
    if (!responseValidation.success) return responseValidation;

    const data = await response.json();
    if (data && data.Items && data.Items.length) {
      return { success: true, products: data.Items };
    }
    return { success: false };
  } catch (error) {
    return {
      success: false,
      message: error.message ?? "network-products-getproducts-fail",
    };
  }
};

export const requestProduct = async (token, product, shippingInfo = {}) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/products/request",
      // "https://9ydsb15ld4.execute-api.eu-central-1.amazonaws.com/products/request",
      {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          productId: product.productId,
          productName: product.name,
          ...shippingInfo,
        }),
      }
    );
    const responseValidation = apiResponseValidator(response);
    if (!responseValidation.success) return responseValidation;

    const data = await response.json();
    if (
      data &&
      data.Item &&
      Object.keys(data.Item).length &&
      data.Item.requestId
    ) {
      return {
        success: true,
        userUpdatedPoints: data.Item.userUpdatedPoints,
        productId: data.Item.productId,
      };
    }
    return { success: false };
  } catch (error) {
    return {
      success: false,
      message: error.message ?? "network-products-requestproduct-fail",
    };
  }
};
