import React from "react";
import { useAuthContext } from "../../../../application/auth/hooks/useAuthContext";
import LoginForm from "../../components/LoginForm/LoginForm";

const LoginPage = () => {
  const authContext = useAuthContext();

  return (
    <div>
      <div className="block-top text-center main-bg-color ">
        <div className="container-fluid"></div>
      </div>
      <div className="block-middle container-xl">
        <div className="row wow fadeInUp">
          <div className="col-12 text-center mt-5 mb-25">
            <h2 className="mb-4">Inserisci le tue credenziali per accedere</h2>
            <LoginForm />
          </div>
        </div>
      </div>
      {authContext.errorMessage && (
        <div className="block-bottom ">
          <div className="container-xl">
            <div className="row wow fadeInUp">
              <div className="col-12 text-center mt-1 mb-3">
                <div id="user_messages">{authContext.errorMessage}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginPage;
