import React, { useState } from "react";
import { useAdminContext } from "./../../../../application/admin/hooks/useAdminContext";

import fatturatoSVG from "./../../../../assets/images/alter/ap-fatturato.svg";
import puntiSVG from "./../../../../assets/images/alter/ap-punti.svg";
import spesiSVG from "./../../../../assets/images/alter/ap-spesi.svg";
import residuiSVG from "./../../../../assets/images/alter/ap-residui.svg";

import Swal from "sweetalert2";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { Link } from "react-router-dom";
import ProductRequestTile from "../../components/ProductRequestTile/ProductRequestTile";

const AdminProfilePage = () => {
  const adminContext = useAdminContext();

  const [enteredUserId, setEnteredUserId] = useState("");
  const [checkedPointsType, setCheckedPointsType] = useState("spot");
  const [enteredPointsToAdd, setEnteredPointsToAdd] = useState(0);

  const searchUserSubmitHandler = (event) => {
    event.preventDefault();
    adminContext.searchUser(enteredUserId);
    setEnteredUserId("");
  };

  const checkedPointsTypeChangeHandler = (event) => {
    setCheckedPointsType(event.target.value);
  };

  const enteredPointsToAddChangeHandler = (event) => {
    setEnteredPointsToAdd(event.target.value);
  };

  const assignPointsSubmitHandler = (event) => {
    event.preventDefault();
    assignPoints(checkedPointsType, enteredPointsToAdd);
    setEnteredPointsToAdd(0);
  };

  const cancelOnClickSubmitHandler = (event) => {
    event.preventDefault();
    setEnteredUserId("");
  };

  const formatRevenue = (revenue) => {
    return parseFloat(revenue).toLocaleString("it-IT", {
      style: "currency",
      currency: "EUR",
    });
  };

  function assignPoints(checkedPointsType, enteredPointsToAdd) {
    Swal.fire({
      title: "",
      text: `Vuoi assegnare ${enteredPointsToAdd} punti all'utente con P.IVA "${adminContext.searchedUser.userId}"?`,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "SI, voglio procedere",
      cancelButtonText: "NO, voglio ripensarci",
    }).then((response) => {
      if (response.isConfirmed) {
        adminContext.assignExtraPoints(checkedPointsType, enteredPointsToAdd);
      }
    });
  }

  return (
    <div>
      <div className="block-top text-center ">
        <div className="container-xl"></div>
      </div>
      <div className="block-middle ">
        <div className="text-center">
          <h2 className="px-4">
            Situazione aggiornata al{" "}
            <span className="main-color bold">
              {new Date().toLocaleDateString("it-IT")}
            </span>
          </h2>
          <div className="row wow fadeInUp">
            <div className="col-12 text-center">
              <div className="mt-30 ">
                <div className="pb-15">
                  <p className="font-ml secondary-color ">
                    In questa area puoi verificare lo stato del fatturato
                    generato da tutti i clienti,{" "}
                    <span className="hode-md">
                      <br />
                    </span>
                    il totale dei punti maturati, spesi, spendibili ed i premi
                    scelti per tipo.
                  </p>
                </div>
                {adminContext.isLoading ? (
                  <p>Caricamento...</p>
                ) : (
                  <div className="third-bg-color pt-3">
                    <div className="container-xl AreaPersonaleSuperuser">
                      <div className="row justify-content-center text-center box-ap-clienti">
                        <div className="col-12 col-md-10 col-xl-5 mb-2 ">
                          <label>
                            Numero totale di P.IVA caricate a sistema
                          </label>
                          <div className="box-totclienti mb-4 ">
                            <input
                              type="text"
                              id="customers_counter"
                              placeholder={
                                adminContext.totalUsersInfo.count ?? 0
                              }
                              autoComplete="off"
                              maxLength="50"
                              className="inputs bold mb-0"
                              disabled
                            />
                          </div>
                          <Link
                            className="d-block mb-4 fs-6"
                            onClick={adminContext.downloadAllUsersProfilesXLS}
                          >
                            Download XLS generale clienti
                          </Link>
                          <Link
                            className="d-block fs-6"
                            onClick={adminContext.downloadAllUsersRequestsXLS}
                          >
                            Download XLS generale richieste premio
                          </Link>
                        </div>
                        <div className="col-12 col-md-10 col-xl-5 my-4 ">
                          <label>Inserisci P.IVA CLIENTE</label>
                          <div className="box-cercaclienti ">
                            <form
                              id="form_x"
                              onSubmit={searchUserSubmitHandler}
                            >
                              <Autocomplete
                                value={enteredUserId}
                                onChange={(event, newValue) => {
                                  setEnteredUserId(newValue ?? "");
                                }}
                                inputValue={enteredUserId}
                                onInputChange={(event, newInputValue) => {
                                  setEnteredUserId(newInputValue ?? "");
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                id="user_code"
                                sx={{
                                  backgroundColor: "white",
                                  borderRadius: "0",
                                  marginBottom: "20px",
                                  "& .MuiInputBase-formControl.MuiInputBase-adornedEnd":
                                    {
                                      borderRadius: "0px",
                                    },
                                }}
                                options={
                                  adminContext.totalUsersInfo.codes ?? []
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    sx={{
                                      borderRadius: "0",
                                    }}
                                  />
                                )}
                              />
                              <div className="flex justify-content-between">
                                <input
                                  className="btn  btn-radius button-standard"
                                  type="submit"
                                  id="form_x_submit"
                                  value="CERCA"
                                  disabled={
                                    !adminContext.totalUsersInfo?.codes?.includes(
                                      enteredUserId
                                    )
                                  }
                                />
                                <input
                                  className="btn  btn-radius button-inverse"
                                  type="submit"
                                  onClick={cancelOnClickSubmitHandler}
                                  id="form_x_cancel"
                                  value="ANNULLA"
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-center text-center mt-4">
                        <div class="mb-4">
                          <strong>
                            Il totale punti maturati è la somma dei punti
                            generata dai fatturati arrotondati.
                          </strong>
                        </div>
                        <div className="col-12 col-md-10 col-xl-5 mb-2 ">
                          <div className="b-radius-0 box-border-main card mb-2 pb-2">
                            <div className="row justify-content-between align-items-center text-center ">
                              <div className="col-12 col-md-6  ">
                                <img
                                  className="max-width"
                                  src={fatturatoSVG}
                                  alt="svg fatturato"
                                />
                                <div className="main-color bold py-1 font-s">
                                  TOTALE FATTURATO
                                </div>
                              </div>
                              <div className="col-12 col-md-6 mb-2 ">
                                <div className="main-color bold py-1 ">
                                  {Object.keys(adminContext.searchedUser)
                                    .length > 0
                                    ? formatRevenue(
                                        adminContext.searchedUser.revenue ?? "0"
                                      )
                                    : formatRevenue(
                                        adminContext.totalUsersInfo.revenue ??
                                          "0"
                                      )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="b-radius-0 box-border-main card p-0 mb-2 pb-2">
                            <div className="row justify-content-between align-items-center text-center ">
                              <div className="col-12 col-md-6  ">
                                <img
                                  className="max-width"
                                  src={puntiSVG}
                                  alt="svg punti"
                                />
                                <div className="main-color bold py-1 font-s">
                                  TOTALE PUNTI MATURATI
                                </div>
                              </div>
                              <div className="col-12 col-md-6 mb-2 ">
                                <div className="main-color bold py-1 ">
                                  {Object.keys(adminContext.searchedUser)
                                    .length > 0
                                    ? (
                                        (adminContext.searchedUser
                                          .pointsRevenue ?? 0) +
                                        (adminContext.searchedUser.pointsSpot ??
                                          0) +
                                        (adminContext.searchedUser
                                          .pointsBonus ?? 0)
                                      ).toLocaleString("it-IT")
                                    : (
                                        adminContext.totalUsersInfo.points ?? 0
                                      ).toLocaleString("it-IT")}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="b-radius-0 box-border-main card p-0  mb-2 pb-2">
                            <div className="row justify-content-between align-items-center text-center ">
                              <div className="col-12 col-md-6  ">
                                <img
                                  className="max-width"
                                  src={spesiSVG}
                                  alt="svg punti spesi"
                                />
                                <div className="main-color bold py-1 font-s">
                                  TOTALE PUNTI SPESI
                                </div>
                              </div>
                              <div className="col-12 col-md-6 mb-2 ">
                                <div className="main-color bold py-1 ">
                                  {Object.keys(adminContext.searchedUser)
                                    .length > 0
                                    ? (
                                        adminContext.searchedUser.pointsUsed ??
                                        0
                                      ).toLocaleString("it-IT")
                                    : (
                                        adminContext.totalUsersInfo
                                          .pointsUsed ?? 0
                                      ).toLocaleString("it-IT")}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="b-radius-0 box-border-main card p-0 mb-2 pb-2">
                            <div className="row justify-content-between align-items-center text-center ">
                              <div className="col-12 col-md-6  ">
                                <img
                                  className="max-width"
                                  src={residuiSVG}
                                  alt="svg punti residui"
                                />
                                <div className="main-color bold py-1 font-s">
                                  TOTALE PUNTI DISPONIBILI
                                </div>
                              </div>
                              <div className="col-12 col-md-6 mb-2 ">
                                <div className="main-color bold py-1 ">
                                  {Object.keys(adminContext.searchedUser)
                                    .length > 0
                                    ? (
                                        adminContext.searchedUser
                                          .pointsAvailable ?? 0
                                      ).toLocaleString("it-IT")
                                    : (
                                        adminContext.totalUsersInfo
                                          .pointsAvailable ?? 0
                                      ).toLocaleString("it-IT")}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {adminContext.isSearching ? (
                          <p>Caricamento utente...</p>
                        ) : (
                          Object.keys(adminContext.searchedUser).length > 0 && (
                            <div className="col-12 col-md-10 col-xl-5 mb-2 ">
                              <form
                                id="form_xx"
                                onSubmit={assignPointsSubmitHandler}
                              >
                                <div className="box-omaggi text-center p-2 p-lg-4 mb-5 pb-5 ">
                                  <p className="main-color font-base pt-1">
                                    Seleziona la tipologia di punti da omaggiare
                                    a "{adminContext.searchedUser.businessName}"
                                  </p>
                                  <div className="flex justify-content-between ps-5 pe-5 pt-2 pb-3">
                                    <div className="">
                                      <input
                                        id="opt01"
                                        name="punti"
                                        type="radio"
                                        value="spot"
                                        className="punti font-base"
                                        checked={checkedPointsType === "spot"}
                                        onChange={
                                          checkedPointsTypeChangeHandler
                                        }
                                        required=""
                                      />
                                      <label className="" htmlFor="opt01">
                                        Doppi Punti
                                      </label>
                                    </div>
                                    <div className="">
                                      <input
                                        id="opt02"
                                        name="punti"
                                        type="radio"
                                        value="bonus"
                                        className="punti font-base"
                                        checked={checkedPointsType === "bonus"}
                                        onChange={
                                          checkedPointsTypeChangeHandler
                                        }
                                        required=""
                                      />
                                      <label className="" htmlFor="opt02">
                                        Omaggio
                                      </label>
                                    </div>
                                  </div>
                                  <div className="box-inviapunti pb-4">
                                    <label>Inserisci il valore punti</label>
                                    <input
                                      type="number"
                                      min="0"
                                      id="points"
                                      value={enteredPointsToAdd}
                                      onChange={enteredPointsToAddChangeHandler}
                                      placeholder="Valore Punti"
                                      autoComplete="off"
                                      className="pb-2 inputs bold "
                                    />
                                    <input
                                      className="btn  btn-radius button-standard w-100 mb-2"
                                      type="submit"
                                      id="form_xx_submit"
                                      value="ASSEGNA"
                                    />
                                  </div>
                                </div>
                              </form>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {Object.keys(adminContext.searchedUser).length > 0 &&
        adminContext.searchedUser.requests?.length > 0 && (
          <div className="block-bottom third-bg-color">
            <div className="body-bg-color" id="tabPremi">
              <div className="container-xl">
                <div className="row wow fadeInUp">
                  <div className="col-12 text-center mt-1 mb-3">
                    <div id="Table-Container" className="p-1 ">
                      <div
                        id="Tabella-Dati"
                        className="table-responsive table-wrapper tabella-dati"
                      >
                        <table className="table m-0">
                          <thead>
                            <tr>
                              <th scope="col" className="">
                                <div className="tab-head py-1">
                                  <span className="secondary-color font-s">
                                    PREMIO
                                  </span>
                                </div>
                              </th>
                              <th scope="col" className="">
                                <div className="tab-head py-1">
                                  <span className="secondary-color font-s">
                                    PUNTI
                                  </span>
                                </div>
                              </th>
                              <th scope="col" className="">
                                <div className="tab-head py-1">
                                  <span className="secondary-color font-s">
                                    DATA{" "}
                                    <span className="hidden-xxs">
                                      RICHIESTA PREMIO
                                    </span>
                                  </span>
                                </div>
                              </th>
                              <th scope="col" className="">
                                <div className="tab-head py-1">
                                  <span className="secondary-color font-s">
                                    INDIRIZZO{" "}
                                    <span className="">DESTINAZIONE</span>
                                  </span>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {adminContext.searchedUser.requests
                              ?.sort((a, b) => b.createdAt - a.createdAt)
                              .map((request) => (
                                <ProductRequestTile
                                  productId={request.productId}
                                  email={adminContext.searchedUser.email}
                                  productCost={request.productCost}
                                  createdAt={request.createdAt}
                                  address={request.address}
                                  houseNumber={request.houseNumber}
                                  city={request.city}
                                />
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      {adminContext.errorMessage && (
        <div className="container-xl">
          <div className="row wow fadeInUp">
            <div className="col-12 text-center mt-1 mb-3">
              <div id="user_messages">{adminContext.errorMessage}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminProfilePage;
