import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useProductsContext } from "../../../../application/products/hooks/useProductsContext";

const PhysicalThankYouPage = () => {
  const productsContext = useProductsContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!productsContext.selectedProduct) {
      navigate("/catalogo");
    }
  }, [productsContext.selectedProduct]);

  return (
    <main className="main-container ">
      <div className="">
        <div className="block-top text-center main-bg-color ">
          <div className="container-fluid"></div>
        </div>
        <div className="block-middle container-xl">
          <div className="row justify-content-center wow fadeInUp">
            <div className="col-12 col-sm-12 text-center">
              <h2 className="mb-2 mt-4 no-border">
                Perfetto! Abbiamo registrato la tua richiesta di
              </h2>
              <div className="">
                <h2 className="main-color mb-4 bold ">
                  1 "{productsContext.selectedProduct.name}"
                </h2>
                <p className="secondary-color">
                  Provvederemo alla spedizione nel più breve tempo possibile,{" "}
                  <span className="hide-md">
                    <br />
                  </span>
                  in ogni caso entro i termini indicati nel regolamento.
                  <br />
                  <br />
                  Grazie e a presto!
                  <br />
                  <br />
                  Team SI Supply
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 text-center mt-4">
              <input
                id="attivaPremi"
                className="btn button-standard btn-radius mb-4 attiva-premi"
                type="button"
                value="TORNA ALLA SELEZIONE PREMI"
                onClick={() => {
                  navigate("/catalogo");
                }}
              />
            </div>
          </div>
        </div>
        <div className="block-bottom ">
          <div className="container-xl">
            <div className="row wow fadeInUp">
              <div className="col-12 text-center my-3">
                <div id="user_messages"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default PhysicalThankYouPage;
