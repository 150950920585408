import React, { useState } from "react";
import { useAuthContext } from "../../../../application/auth/hooks/useAuthContext";

const PasswordAskResetPage = () => {
  const authContext = useAuthContext();
  const [enteredEmail, setEnteredEmail] = useState("");

  authContext.setErrorMessage("");

  const enteredEmailOnChangeHandler = (event) => {
    setEnteredEmail(event.target.value);
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    authContext.requestNewPassword(enteredEmail);
  };

  return (
    <main className="main-container ">
      <div className="">
        <div className="block-top text-center main-bg-color ">
          <div className="container-fluid"></div>
        </div>
        <div className="block-middle container-xl">
          <div className="row justify-content-center wow fadeInUp">
            <div className="col-12 col-sm-12 text-center">
              <h2 className="mb-4">
                Compila il campo sotto con l’indirizzo e-mail{" "}
                <span className="hide-mobile">
                  <br />
                </span>
                che hai usato in fase di registrazione
              </h2>
              <form id="form_reset_password" onSubmit={onSubmitHandler}>
                <div className="form-small">
                  <div className="mb-10 mt-30">
                    <input
                      value={enteredEmail}
                      onChange={enteredEmailOnChangeHandler}
                      type="text"
                      id="user_email"
                      placeholder="User (e-mail)"
                      autoComplete="off"
                      maxLength="50"
                      className="inputs"
                      required
                    />
                  </div>
                  <div className="mt-0 mb-30">
                    <input
                      className="btn  btn-radius button-standard w-85"
                      type="submit"
                      id="form_reset_submit"
                      value="CONFERMA"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="block-bottom ">
          <div className="container-xl">
            <div className="row wow fadeInUp">
              <div className="col-12 text-center my-3">
                <div id="user_messages">{authContext.errorMessage ?? ""}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default PasswordAskResetPage;
