import { Navigate, useLocation } from "react-router-dom";

function ProtectedRoute({ userIsLoggedIn, children }) {
  const location = useLocation();

  if (location.pathname !== "/" && !userIsLoggedIn) {
    return <Navigate to="/" />;
  }

  return children;
}

export default ProtectedRoute;
