import { useContext } from "react";
import GeoUtilsContext from "../context/geo-utils-context";

export const useGeoUtilsContext = () => {
  const context = useContext(GeoUtilsContext);

  if (!context) {
    throw Error("useGeoUtilsContext must be used inside his context");
  }

  return context;
};
