import React, { useState } from "react";

const ProductRequestTile = (productRequest) => {
  const [productImage, setProductImage] = useState("");
  import(
    `./../../../../assets/images/products/${productRequest.productId}.jpg`
  ).then((image) => setProductImage(image.default));

  let address = "";

  if (productRequest.address) {
    address =
      productRequest.address +
      " " +
      (productRequest.houseNumber ?? "") +
      " - " +
      (productRequest.city ?? "");
  } else {
    address = productRequest.email;
  }

  return (
    <tr key={productRequest.productId}>
      <td className="">
        <p className="mb-1">
          <img
            className="max-width  "
            src={productImage}
            alt="immagine prodotto richiesto"
          />
        </p>
      </td>
      <td className="">
        <p className="bold">{productRequest.productCost}</p>
      </td>
      <td className="">
        <p className="bold">
          {new Date(productRequest.createdAt).toLocaleDateString("it-IT")}
        </p>
      </td>
      <td className="">
        <p className="font-special secondary-color">{address}</p>
      </td>
    </tr>
  );
};

export default ProductRequestTile;
