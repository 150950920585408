import React from "react";
import CookieSection from "./../../components/CookieSection/CookieSection";

const CookiePage = () => {
  return (
    <div className="">
      <div className="block-top text-center main-bg-color ">
        <div className="container-fluid"></div>
      </div>
      <div className="block-middle container-xl">
        <div className="row wow fadeInUp">
          <div className="col-12 text-center mt-5 mb-25">
            <CookieSection />
          </div>
        </div>
      </div>
      <div className="block-bottom ">
        <div className="container-xl">
          <div className="row wow fadeInUp">
            <div className="col-12 text-center my-3">
              <div id="user_messages"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiePage;
