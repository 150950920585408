import React from "react";
import { useProductsContext } from "../../../../application/products/hooks/useProductsContext";
import CatalogueForm from "../../components/CatalogueForm/CatalogueForm";
import SpinnerContainer from "./../../../common/components/SpinnerContainer/SpinnerContainer";

const CataloguePage = () => {
  const productsContext = useProductsContext();

  return (
    <div>
      <div className="block-top text-center main-bg-color ">
        <div className="container-fluid"></div>
      </div>
      <div className="block-middle ">
        <div className="">
          <div className="row wow fadeInUp">
            <div className="col-12 text-center mt-5">
              <div className="mt-50 ">
                {productsContext.isLoading ? (
                  <SpinnerContainer height={"500px"} width={"100%"} />
                ) : (
                  <CatalogueForm />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {productsContext.errorMessage && (
        <div className="block-bottom third-bg-color">
          <div className="container-xl">
            <div className="row wow fadeInUp">
              <div className="col-12 text-center mt-1 mb-3">
                <div id="user_messages">{productsContext.errorMessage}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CataloguePage;
