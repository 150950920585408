import { apiResponseValidator } from "../api-response-validator";

export const userLogIn = async (email, password) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/user/login",
      // "https://9ydsb15ld4.execute-api.eu-central-1.amazonaws.com/user/login",
      {
        method: "POST",
        body: JSON.stringify({
          email: email,
          password: password,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    const responseValidation = apiResponseValidator(response);
    if (!responseValidation.success) return responseValidation;

    const data = await response.json();
    if (data.auth && data.status === "SUCCESS" && data.token) {
      if (typeof data.token === "string" && data.token.length) {
        return {
          success: true,
          auth: data.auth,
          token: data.token,
        };
      }
    }
    return {
      success: false,
      auth: false,
      token: null,
    };
  } catch (error) {
    return {
      success: false,
      auth: false,
      token: null,
      message: error.message ?? "network-auth-userlogin-fail",
    };
  }
};

export const getUserProfile = async (token) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/user/profile",
      // "https://9ydsb15ld4.execute-api.eu-central-1.amazonaws.com/user/profile",
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const responseValidation = apiResponseValidator(response);
    if (!responseValidation.success) return responseValidation;

    const data = await response.json();
    if (data && Object.keys(data).length) {
      localStorage.setItem("auth", JSON.stringify({ token: token }));
      return { success: true, userProfile: data };
    }
    return { success: false };
  } catch (error) {
    return {
      success: false,
      message: error.message ?? "network-auth-getuserprofile-fail",
    };
  }
};

export const updateUserProfile = async (token, userUpdateData) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/user/update",
      // "https://9ydsb15ld4.execute-api.eu-central-1.amazonaws.com/user/update",
      {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          password: userUpdateData.password,
          address: userUpdateData.address,
          zip: userUpdateData.zip,
          cityId: userUpdateData.cityId,
          provinceId: userUpdateData.provinceId,
          phoneNumberPrefix: userUpdateData.phoneNumberPrefix,
          phoneNumber: userUpdateData.phoneNumber,
          referentFirstName: userUpdateData.referentFirstName,
          referentLastName: userUpdateData.referentLastName,
          privacy: userUpdateData.privacy,
          privacyRecontact: userUpdateData.privacyRecontact,
        }),
      }
    );
    const responseValidation = apiResponseValidator(response);
    if (!responseValidation.success) return responseValidation;

    const data = await response.json();
    if (data && Object.keys(data).length) {
      localStorage.setItem(
        "auth",
        JSON.stringify({ token: token, userProfile: data })
      );
      return { success: true, userProfile: data };
    }
    return { success: false };
  } catch (error) {
    return {
      success: false,
      message: error.message ?? "network-auth-getuserprofile-fail",
    };
  }
};

export const getUserProductRequests = async (token) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/user/requests",
      // "https://9ydsb15ld4.execute-api.eu-central-1.amazonaws.com/user/requests",
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const responseValidation = apiResponseValidator(response);
    if (!responseValidation.success) return responseValidation;

    const data = await response.json();
    if (data && data.Items) {
      return {
        success: true,
        requests: data.Items.sort((a, b) => b.createdAt - a.createdAt),
      };
    }
    return { success: false };
  } catch (error) {
    return {
      success: false,
      message: error.message ?? "network-auth-getuserrequests-fail",
    };
  }
};

export const requestNewPassword = async (email) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL +
        `/user/reset?email=${encodeURIComponent(email)}`,
      // `https://9ydsb15ld4.execute-api.eu-central-1.amazonaws.com/user/reset?email=${encodeURIComponent(
      //   email
      // )}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    const responseValidation = apiResponseValidator(response);
    if (!responseValidation.success) return responseValidation;

    const data = await response.json();
    if (data && data.success) {
      return {
        success: true,
      };
    }
    return { success: false };
  } catch (error) {
    return {
      success: false,
      message: error.message ?? "network-auth-requestnewpassword-fail",
    };
  }
};

export const sendNewPassword = async (resetToken, newPassword) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/user/reset",
      // "https://9ydsb15ld4.execute-api.eu-central-1.amazonaws.com/user/reset",
      {
        method: "POST",
        body: JSON.stringify({
          token: resetToken,
          password: newPassword,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    const responseValidation = apiResponseValidator(response);
    if (!responseValidation.success) return responseValidation;

    const data = await response.json();
    if (data && data.success) {
      return {
        success: true,
      };
    }
    return {
      success: false,
    };
  } catch (error) {
    return {
      success: false,
      message: error.message ?? "network-auth-sendnewpassword-fail",
    };
  }
};
