import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getUserProfile,
  updateUserProfile,
  userLogIn,
  getUserProductRequests,
  requestNewPassword,
  sendNewPassword,
} from "../../../services/auth/lambdaAuth";

const AuthContext = createContext();

export const AuthContextProvider = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const pswRegex = "^(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_=+{};:,<.>-])(?=.{8,})";

  const [isLoading, setIsLoading] = useState(true);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [token, setToken] = useState(null);
  const [userProfile, setUserProfile] = useState({});

  const [isLoadingProductRequest, setIsLoadingProductRequest] = useState(false);
  const [productRequests, setProductRequests] = useState([]);

  useEffect(() => {
    if (!userIsLoggedIn) {
      const localStorageAuth = getAuthFromLocalStorage();
      if (localStorageAuth) {
        setToken(localStorageAuth.token);
        getUserProfileHandler(localStorageAuth.token);
      }
    }
    setErrorMessage("");
  }, []);

  useEffect(() => {
    if (!isLoading) {
      if (userIsLoggedIn && userProfile.email) {
        if (
          location.pathname !== "/profilo/aggiorna" &&
          userProfile.role === "user" &&
          !userProfile.firstLogin
        ) {
          navigate("/profilo/aggiorna");
        } else if (location.pathname === "/") {
          navigate("/profilo");
        }
      }
    }
  }, [userIsLoggedIn, location, isLoading]);

  const logoutHandler = () => {
    localStorage.removeItem("auth");
    setUserIsLoggedIn(false);
    setToken(null);
    setUserProfile({});
    setIsLoading(false);
  };

  const loginHandler = (email, password) => {
    setIsLoading(true);
    userLogIn(email, password).then((loginResponse) => {
      if (loginResponse.success && loginResponse.auth) {
        setErrorMessage("");
        setToken(loginResponse.token);
        getUserProfileHandler(loginResponse.token);
      } else {
        setErrorMessage("Utente non autorizzato.");
        logoutHandler();
      }
    });
  };

  const getUserProfileHandler = (token) => {
    setIsLoading(true);
    getUserProfile(token).then((profileResponse) => {
      if (profileResponse.success && profileResponse.userProfile) {
        setErrorMessage("");
        setUserProfile(profileResponse.userProfile);
        setUserIsLoggedIn(true);
        setIsLoading(false);
      } else {
        setErrorMessage("Profilo utente non trovato.");
        logoutHandler();
      }
    });
  };

  const updateUserProfileHandler = (newUserData) => {
    setIsLoading(true);
    if (!newUserData.password.match(pswRegex)) {
      setErrorMessage(
        "Inserisci una password di almeno 8 caratteri e che comprenda almeno una lettera, un numero e un carattere speciale (!@#$%^&*()-_=+{};:,<.>)"
      );
      setIsLoading(false);
      return;
    }
    updateUserProfile(token, newUserData).then((profileResponse) => {
      if (profileResponse.success) {
        setErrorMessage("");
        setUserProfile(profileResponse.userProfile);
        setIsLoading(false);
      } else {
        setErrorMessage("Profilo utente non aggiornato.");
        logoutHandler();
      }
    });
  };

  const refreshUserProfileHandler = () => {
    // setIsLoading(true);
    getUserProfile(token).then((profileResponse) => {
      if (profileResponse.success && profileResponse.userProfile) {
        // setErrorMessage("");
        setUserProfile(profileResponse.userProfile);
        // setIsLoading(false);
      } else {
        setErrorMessage("Profilo utente non trovato.");
        logoutHandler();
      }
    });
  };

  const getUserProductRequestsHandler = () => {
    setIsLoadingProductRequest(true);
    getUserProductRequests(token).then((requestsResponse) => {
      if (requestsResponse.success) {
        setErrorMessage("");
        setProductRequests(requestsResponse.requests);
      } else {
        setErrorMessage("Richieste premi utente non trovate.");
      }
      setIsLoadingProductRequest(false);
    });
  };

  const requestNewPasswordHandler = (email) => {
    setIsLoading(true);
    requestNewPassword(email).then((requestsResponse) => {
      if (requestsResponse.success) {
        if (location.pathname !== "/password/reset/email") {
          setErrorMessage("");
          navigate("/password/reset/email");
        }
      } else {
        setErrorMessage("Richiesta nuova password fallita.");
      }
      setIsLoading(false);
    });
  };

  const sendNewPasswordHandler = (param, newEmail) => {
    setIsLoading(true);
    sendNewPassword(param, newEmail).then((requestsResponse) => {
      if (requestsResponse.success) {
        if (location.pathname !== "/password/reset/success") {
          setErrorMessage("");
          navigate("/password/reset/success");
        }
      } else {
        setErrorMessage("Aggiornamento password fallito.");
      }
      setIsLoading(false);
    });
  };

  function getAuthFromLocalStorage() {
    setIsLoading(true);
    const authStr = localStorage.getItem("auth");
    if (!authStr) {
      setIsLoading(false);
      return false;
    }
    return JSON.parse(authStr);
    //TODO add check for time validity
  }

  const contextValue = {
    isLoading: isLoading,
    isLoggedIn: userIsLoggedIn,
    errorMessage: errorMessage,
    setErrorMessage: setErrorMessage,
    token: token,
    userProfile: userProfile,
    login: loginHandler,
    logout: logoutHandler,
    refreshUserProfile: refreshUserProfileHandler,
    setUserProfile: setUserProfile,
    updateUserProfile: updateUserProfileHandler,
    getUserProductRequests: getUserProductRequestsHandler,
    isLoadingProductRequest: isLoadingProductRequest,
    productRequests: productRequests,
    requestNewPassword: requestNewPasswordHandler,
    sendNewPassword: sendNewPasswordHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
