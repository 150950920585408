import { createContext, useEffect, useState } from "react";
import { getProvinces, getCities } from "../../../services/utils/geo-utils.js";
import { useAuthContext } from "../../auth/hooks/useAuthContext.js";

const GeoUtilsContext = createContext();

export const GeoUtilsContextProvider = (props) => {
  const authContext = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState("");

  useEffect(() => {
    if (authContext?.token && provinces?.length === 0) {
      getProvincesHandler();
    }
  }, []);

  const getProvincesHandler = () => {
    setIsLoading(true);

    getProvinces(authContext.token).then((provincesResponse) => {
      if (provincesResponse.success && provincesResponse.provinces.length) {
        setProvinces(provincesResponse.provinces);
      } else {
        // error
      }
      setIsLoading(false);
    });
  };

  const setSelectedProvinceHandler = (provinceId) => {
    setSelectedProvince(provinceId);
    getCitiesHandler(provinceId);
  };

  const getCitiesHandler = (provinceId) => {
    // setIsLoading(true);
    getCities(authContext.token, provinceId).then((citiesResponse) => {
      if (citiesResponse.success && citiesResponse.cities.length) {
        setCities(citiesResponse.cities);
      } else {
        // error
      }
      setIsLoading(false);
    });
  };

  const contextValue = {
    isLoading: isLoading,
    provinces: provinces,
    cities: cities,
    selectedProvince: selectedProvince,
    setSelectedProvince: setSelectedProvinceHandler,
    getProvinces: getProvincesHandler,
    getCities: getCitiesHandler,
  };

  return (
    <GeoUtilsContext.Provider value={contextValue}>
      {props.children}
    </GeoUtilsContext.Provider>
  );
};

export default GeoUtilsContext;
