import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "./application/auth/contexts/auth-context";
import { ProductsContextProvider } from "./application/products/contexts/products-context";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "./App";

// css
import "./index.css";

Sentry.init({
  dsn: "https://512529bb93d742bf93795d0726b7fd7a@o4504321924988928.ingest.sentry.io/4504327737442304",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <BrowserRouter>
    <AuthContextProvider>
      <ProductsContextProvider>
        <App />
      </ProductsContextProvider>
    </AuthContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
