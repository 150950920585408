export function apiResponseValidator(response) {
  const authCheck = checkAutorization(response);
  if (!authCheck.success) return authCheck;

  // other checks

  return {
    success: true,
  };
}

function checkAutorization(response) {
  if (response.status === 403) {
    return {
      success: false,
      isAuthorized: false,
      message: "not-authorized",
    };
  }
  return { success: true };
}
