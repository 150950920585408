import { useContext } from "react";
import AdminContext from "../contexts/admin-context";

export const useAdminContext = () => {
  const context = useContext(AdminContext);

  if (!context) {
    throw Error("useAdminContext must be used inside his context");
  }

  return context;
};
