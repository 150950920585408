import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useProductsContext } from "../../../../application/products/hooks/useProductsContext";

const OnlineThankYouPage = () => {
  const productsContext = useProductsContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!productsContext.selectedProduct) {
      navigate("/catalogo");
    }
  }, [productsContext.selectedProduct]);

  return (
    <main className="main-container ">
      <div className="">
        <div className="block-top text-center main-bg-color ">
          <div className="container-fluid"></div>
        </div>
        <div className="block-middle container-xl">
          <div className="row justify-content-center wow fadeInUp">
            <div className="col-12 col-sm-12 text-center">
              <p className="font-ml secondary-color mt-50">
                La tua richiesta premio è stata registrata e il premio ti verrà
                spedito al tuo indirizzo mail.
                <br />
                <br />
                Verifica anche la casella di posta dello spam e delle offerte
                nel caso le nostre e-mail siano finite lì.
              </p>
            </div>
          </div>
        </div>

        <div className="block-bottom ">
          <div className="container-xl">
            <div className="row wow fadeInUp">
              <div className="col-12 text-center my-3">
                <div id="user_messages"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default OnlineThankYouPage;
