import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import privacyPDF from "./../../../../assets/documents/privacy.pdf";

const Footer = () => {
  const location = useLocation();

  return (
    <footer id="Footer" className="clearfix">
      <div className="text-center top-footer relative"></div>
      <div className="copyright relative py-4">
        <div className="container-fluid ">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <p className="font-xxs text-center m-0">
                <Link to={privacyPDF} target="_blank" className="no-underline">
                  Privacy
                </Link>{" "}
                -{" "}
                <Link
                  to={"/cookie"}
                  className={`no-underline ${
                    location.pathname === "/cookie" ? "disabled-link" : ""
                  }`}
                >
                  Cookie
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
