import React from "react";
import { useAuthContext } from "../../../../application/auth/hooks/useAuthContext";
// assets
import CenterOne from "./../../../../assets/images/alter/vu-center-1.png";
import CenterTwo from "./../../../../assets/images/alter/vu-center-2.png";

const Jumbo = () => {
  const authContext = useAuthContext();

  const formattedYearString =
    new Date().getFullYear().toString() +
    "/" +
    (new Date().getFullYear() + 1).toString().substring(2);

  return (
    <section id="Banner">
      <div className="banner-wrapper">
        <div className="container-xl">
          <div className="block-banner relative">
            <div className="row justify-content-center align-items-center">
              <div className="block-overlay h-100">
                <div className="col-12 col-md-5 col-lg-6 pt-0 pt-sm-3 pt-md-5 mt-md-5 wow slideInLeft relative h-100">
                  <h2 className="font-ml main-color pt-3 pt-md-5">
                    Operazione incentive
                  </h2>
                  <h1>SI SUPPLY REWARDS</h1>
                  <p className="font-m">{formattedYearString}</p>

                  {authContext.userProfile.referentFirstName ? (
                    <p className="user-info block-overlay">
                      Benvenuto {authContext.userProfile.referentFirstName}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="col-12 relative po-0 pt-0 pt-md-0 mt-5 mt-sm-1 mt-md-5  mt-lg-0">
                <img
                  alt="jumbo img 1"
                  className="max-width w-100  wow fadeIn"
                  data-wow-delay="0.3s"
                  src={CenterOne}
                />
                <div className="block-overlay">
                  <img
                    alt="jumbo img 2"
                    className="max-width w-100 wow  fadeIn "
                    data-wow-delay="0.3s"
                    src={CenterTwo}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Jumbo;
