import React from "react";
import { Link } from "react-router-dom";
import RegolamentoPDF from "./../../../../assets/documents/regolamento.pdf";
import FornitoriPDF from "./../../../../assets/documents/fornitori.pdf";

const IntroPage = () => {
  return (
    <main>
      <div className="block-top text-center main-bg-color ">
        <div className="container-fluid"></div>
      </div>
      <div className="block-middle container-xl">
        <div className="row justify-content-center wow fadeInUp">
          <div className="col-12 col-sm-12 text-center">
            <h2 className="mb-4 mt-3">Presentazione del programma incentive</h2>
            <p className="secondary-color">
              Dal <strong>1 ottobre 2022 al 31 dicembre 2023</strong> per tutti
              gli acquisti effettuati presso i nostri Fornitori Centralizzati,
              riceverai <strong>1 punto per ogni 10€</strong> spesi grazie al
              nuovo programma di Incentivazione <strong>SI Supply</strong>.
              <br />
              <br />
              Raggiuta la soglia minima di 15.000€ + iva di fatturato inizierai
              ad accumulare i punti, visita la sezione premi per richiedere il
              premio in base ai punti maturati.
              <br />
              <br />
              La richiesta premi potrà essere effettuata in qualsiasi momento e
              comunque entro e non oltre il <strong>15 marzo 2024</strong>.
              <br />
              <br />
              Per Maggiori dettagli consulta il{" "}
              <Link to={RegolamentoPDF} target="_blank" rel="noreferrer">
                regolamento
              </Link>{" "}
              completo.
              <br />
              <br />
              Consulta l’
              <Link to={FornitoriPDF} target="_blank" rel="noreferrer">
                elenco dei fornitori
              </Link>{" "}
              centralizzati.
            </p>
          </div>
        </div>
      </div>
      <div className="block-bottom ">
        <div className="container-xl">
          <div className="row wow fadeInUp">
            <div className="col-12 text-center my-3">
              <div id="user_messages"></div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default IntroPage;
