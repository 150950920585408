import { apiResponseValidator } from "../api-response-validator";

export const getProvinces = async (token) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/utils/provinces",
      // "https://9ydsb15ld4.execute-api.eu-central-1.amazonaws.com/utils/provinces",
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const responseValidation = apiResponseValidator(response);
    if (!responseValidation.success) return responseValidation;

    const data = await response.json();
    if (data && data.Items && data.Items.length) {
      return { success: true, provinces: data.Items };
    }
    return { success: false };
  } catch (error) {
    return {
      success: false,
      message: error.message ?? "network-utils-getprovinces-fail",
    };
  }
};

export const getCities = async (token, provinceId) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL +
        `/utils/cities?provinceId=${encodeURIComponent(provinceId)}`,
      // `https://9ydsb15ld4.execute-api.eu-central-1.amazonaws.com/utils/cities?provinceId=${provinceId}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const responseValidation = apiResponseValidator(response);
    if (!responseValidation.success) return responseValidation;

    const data = await response.json();
    if (data && data.Items && data.Items.length) {
      data.Items.sort((a, b) => a.name.localeCompare(b.name));
      return { success: true, cities: data.Items };
    }
    return { success: false };
  } catch (error) {
    return {
      success: false,
      message: error.message ?? "network-utils-getcities-fail",
    };
  }
};
