import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UpdateForm from "../../components/UpdateForm/UpdateForm";
import { useAuthContext } from "../../../../application/auth/hooks/useAuthContext";
import { GeoUtilsContextProvider } from "../../../../application/utils/context/geo-utils-context";

const UpdateUserPage = () => {
  const authContext = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (authContext.userProfile.firstLogin) {
      navigate("/profilo", { replace: true });
    }
  }, [authContext.userProfile.firstLogin]);

  return (
    <div>
      <div className="block-top text-center main-bg-color ">
        <div className="container-fluid"></div>
      </div>
      <div className="block-middle container-xl">
        <div className="row wow fadeInUp">
          <div className="col-12 text-center mt-5 mb-25">
            <h2 className="mb-4">
              Completa il form con i dati del tuo punto di vendita:
            </h2>
            <GeoUtilsContextProvider>
              <UpdateForm />
            </GeoUtilsContextProvider>
          </div>
        </div>
      </div>
      {authContext.errorMessage && (
        <div className="block-bottom ">
          <div className="container-xl">
            <div className="row wow fadeInUp">
              <div className="col-12 text-center mt-1 mb-3">
                <div id="user_messages">{authContext.errorMessage}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdateUserPage;
