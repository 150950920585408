import React, { useState } from "react";
import { useAuthContext } from "../../../../application/auth/hooks/useAuthContext";
import { useSearchParams } from "react-router-dom";

const PasswordInsertNewPage = () => {
  const authContext = useAuthContext();
  const [searchParams] = useSearchParams();
  const pswRegex = "^(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_=+{};:,<.>-])(?=.{8,})";

  const [enteredFirstPassword, setEnteredFirstPassword] = useState("");
  const [enteredSecondPassword, setEnteredSecondPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const enteredFirstPasswordChangeHandler = (event) => {
    setEnteredFirstPassword(event.target.value);
  };

  const enteredSecondPasswordChangeHandler = (event) => {
    setEnteredSecondPassword(event.target.value);
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (!enteredFirstPassword.match(pswRegex)) {
      setErrorMessage(
        "Inserisci una password di almeno 8 caratteri e che comprenda almeno una lettera, un numero e un carattere speciale (!@#$%^&*()-_=+{};:,<.>)"
      );
    } else if (enteredFirstPassword !== enteredSecondPassword) {
      setErrorMessage("Le password non corrispondono");
    } else {
      setErrorMessage("");
      authContext.sendNewPassword(
        searchParams.get("token"),
        enteredFirstPassword
      );
    }
  };

  return (
    <main className="main-container ">
      <div className="">
        <div className="block-top text-center main-bg-color ">
          <div className="container-fluid"></div>
        </div>
        <div className="block-middle container-xl">
          <div className="row justify-content-center wow fadeInUp">
            <div className="col-12 col-sm-12 text-center">
              <h2 className="mb-4">Inserisci la tua nuova password</h2>
              <form id="form_change_password" onSubmit={onSubmitHandler}>
                <div className="form-small">
                  <div className="mt-35">
                    <input
                      value={enteredFirstPassword}
                      onChange={enteredFirstPasswordChangeHandler}
                      type="password"
                      id="user_password"
                      placeholder="Password*"
                      autoComplete="off"
                      maxLength="50"
                      className="inputs"
                      required
                    />
                    <input
                      value={enteredSecondPassword}
                      onChange={enteredSecondPasswordChangeHandler}
                      type="password"
                      id="user_password_confirm"
                      placeholder="Ripeti Password*"
                      autoComplete="off"
                      maxLength="50"
                      className="inputs mt-3"
                      required
                    />
                  </div>
                  <div className="mt-10">
                    <input
                      className="btn btn-radius button-standard w-85"
                      type="submit"
                      id="form_reset_submit"
                      value="CONFERMA"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {errorMessage && (
          <div className="block-bottom ">
            <div className="container-xl">
              <div className="row wow fadeInUp">
                <div className="col-12 text-center my-3">
                  <div id="user_messages">{errorMessage}</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

export default PasswordInsertNewPage;
