import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useAuthContext } from "../../../../application/auth/hooks/useAuthContext";
import { useProductsContext } from "../../../../application/products/hooks/useProductsContext";

const PhysicalRequestForm = () => {
  const authContext = useAuthContext();
  const productsContext = useProductsContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!productsContext.selectedProduct) {
      navigate("/catalogo");
    }
  }, [productsContext.selectedProduct]);

  const [newShippingInfo, setNewShippingInfo] = useState({
    businessName: authContext.userProfile.businessName ?? "",
    firstName: authContext.userProfile.referentFirstName ?? "",
    lastName: authContext.userProfile.referentLastName ?? "",
    address: authContext.userProfile.address ?? "",
    houseNumber: authContext.userProfile.houseNumber ?? "",
    zip: authContext.userProfile.zip ?? "",
    city: authContext.userProfile.cityName ?? "",
    intercom: "",
    phoneNumber: authContext.userProfile.phoneNumber ?? "",
    at: "",
  });

  const newShippingInfoChangeHandler = (event) => {
    setNewShippingInfo((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const requestProductSubmitHandler = (event) => {
    event.preventDefault();
    Swal.fire({
      title: "",
      text: `Vuoi procedere con la richiesta di "${productsContext.selectedProduct.name.toLowerCase()}"?`,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "SI, voglio procedere",
      cancelButtonText: "NO, voglio ripensarci",
    }).then((response) => {
      if (response.isConfirmed) {
        productsContext.requestProduct(
          productsContext.selectedProduct,
          newShippingInfo
        );
      }
    });
  };

  return (
    <form id="form_register" onSubmit={requestProductSubmitHandler}>
      <div className="container-xl">
        <h2 className="mb-5 font-ml">
          Compila il form con l’indirizzo di spedizione dove recapitare il
          premio che hai selezionato.
        </h2>
        <div className="row justify-content-center text-center">
          <div className="col-12 col-md-10 col-xl-7">
            <div className="row text-center justify-content-center">
              <div className="col-12 col-sm-12 align-left ">
                <input
                  type="text"
                  name="businessName"
                  value={newShippingInfo.businessName}
                  id="user_ragione_sociale"
                  placeholder="Ragione sociale punto vendita*"
                  autoComplete="off"
                  className="inputs"
                  maxLength="100"
                  disabled
                  required
                />
              </div>
            </div>
            <div className="row  text-center justify-content-center">
              <div className="col-12 col-sm-6 ">
                <input
                  type="text"
                  name="firstName"
                  value={newShippingInfo.firstName}
                  onChange={newShippingInfoChangeHandler}
                  id="user_first_name"
                  placeholder="Nome*"
                  autoComplete="off"
                  className="inputs"
                  maxLength="50"
                  required
                />
              </div>
              <div className="col-12 col-sm-6 ">
                <input
                  type="text"
                  name="lastName"
                  value={newShippingInfo.lastName}
                  onChange={newShippingInfoChangeHandler}
                  id="user_last_name"
                  placeholder="Cognome*"
                  autoComplete="off"
                  className="inputs"
                  maxLength="50"
                  required
                />
              </div>
            </div>
            <div className="row  text-center justify-content-center">
              <div className="col-12 col-sm-6 ">
                <input
                  autoComplete="off"
                  type="text"
                  id="user_address"
                  name="address"
                  value={newShippingInfo.address}
                  onChange={newShippingInfoChangeHandler}
                  maxLength="100"
                  placeholder="Indirizzo*"
                  className="inputs"
                  required
                />
              </div>
              <div className="col-12 col-sm-6 ">
                <div className="row">
                  <div className="col-sm-6">
                    <input
                      autoComplete="off"
                      type="text"
                      id="user_house_number"
                      name="houseNumber"
                      value={newShippingInfo.houseNumber}
                      onChange={newShippingInfoChangeHandler}
                      maxLength="10"
                      placeholder="N. civico*"
                      className="inputs"
                      required
                    />
                  </div>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      id="user_zip_code"
                      name="zip"
                      value={newShippingInfo.zip}
                      onChange={newShippingInfoChangeHandler}
                      maxLength="5"
                      required
                      autoComplete="off"
                      placeholder="CAP*"
                      className="inputs"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row  text-center justify-content-center">
              <div className="col-12 col-sm-6 ">
                <input
                  type="text"
                  id="user_city"
                  name="city"
                  value={newShippingInfo.city}
                  onChange={newShippingInfoChangeHandler}
                  maxLength="100"
                  required
                  autoComplete="off"
                  placeholder="Località*"
                  className="inputs"
                />
              </div>
              <div className="col-12 col-sm-6 ">
                <input
                  autoComplete="off"
                  type="text"
                  id="user_intercom"
                  name="intercom"
                  value={newShippingInfo.intercom}
                  onChange={newShippingInfoChangeHandler}
                  maxLength="50"
                  placeholder="Citofono"
                  className="inputs"
                />
              </div>
            </div>
            <div className="row  text-center justify-content-center">
              <div className="col-12 col-sm-6 ">
                <input
                  autoComplete="off"
                  type="text"
                  id="user_phone_number"
                  name="phoneNumber"
                  value={newShippingInfo.phoneNumber}
                  onChange={newShippingInfoChangeHandler}
                  maxLength="10"
                  placeholder="Numero telefonico*"
                  className="inputs"
                  required
                />
              </div>
              <div className="col-12 col-sm-6 ">
                <input
                  autoComplete="off"
                  type="text"
                  id="user_at"
                  name="at"
                  value={newShippingInfo.at}
                  onChange={newShippingInfoChangeHandler}
                  maxLength="50"
                  placeholder="Presso"
                  className="inputs"
                />
              </div>
            </div>
            <div className="row justify-content-center ">
              <div className="col-12  align-left ">
                <p className="font-s secondary-color">*campi obbligatori</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center text-center">
          <div className="col-12 col-md-10 col-xl-7">
            <input
              className="btn button-standard btn-radius "
              type="submit"
              id="form_update_submit"
              value="CONFERMA"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default PhysicalRequestForm;
