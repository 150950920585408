import React from "react";
import { SpinnerDotted } from "spinners-react";

const SpinnerContainer = (props) => {
  return (
    <div className="container">
      <div className="row">
        <div
          className="col-12 d-flex flex-column justify-content-center align-items-center"
          style={{ height: props.height, width: props.width }}
        >
          <SpinnerDotted
            size={120}
            thickness={120}
            speed={60}
            color="#2670AD"
          />
          <p className="font-l bold mt-4">Loading</p>
        </div>
      </div>
    </div>
  );
};

export default SpinnerContainer;
