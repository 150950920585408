import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../../../application/auth/hooks/useAuthContext";
import { Link, useNavigate } from "react-router-dom";
import ProductRequestTile from "../../components/ProductRequestTile/ProductRequestTile";

import sogliaSVG from "./../../../../assets/images/alter/ap-soglia.svg";
import fatturatoSVG from "./../../../../assets/images/alter/ap-fatturato.svg";
import puntiSVG from "./../../../../assets/images/alter/ap-punti.svg";
import spesiSVG from "./../../../../assets/images/alter/ap-spesi.svg";
import residuiSVG from "./../../../../assets/images/alter/ap-residui.svg";
import manlevaPDF from "./../../../../assets/documents/manleva.pdf";

const UserProfilePage = () => {
  const authContext = useAuthContext();
  const navigate = useNavigate();

  const [areRevenueDetailsOpen, setAreRevenueDetailsOpen] = useState(false);
  const [percentageToMinRevenue, setPercentageToMinRevenue] = useState(0);
  const [aggregatedRevenuesObj, setAggregatedRevenuesObj] = useState(Object());

  useEffect(() => {
    setPercentageToMinRevenue(
      getPercentageDifference(authContext.userProfile.revenue, 15000)
    );

    if (authContext?.userProfile?.revenues?.length > 0) {
      setAggregatedRevenuesObj(
        authContext.userProfile.revenues.reduce((accumulator, currentValue) => {
          if (Object.keys(accumulator).includes(currentValue.supplierName)) {
            accumulator[currentValue.supplierName] += parseFloat(
              currentValue.revenue
            );
          } else {
            accumulator[currentValue.supplierName] = parseFloat(
              currentValue.revenue
            );
          }
          return accumulator;
        }, Object())
      );
    }
  }, []);

  function getPercentageDifference(revenue, threshold) {
    const difference = revenue - threshold;
    let percentage = 0;
    if (difference > 0 || difference < 0) {
      percentage = difference / threshold;
    }
    return percentage;
  }

  const setAreRevenueDetailsOpenClickHandler = () => {
    setAreRevenueDetailsOpen((prev) => !prev);
  };

  const getRequestsDetailsClickHandler = () => {
    authContext.getUserProductRequests();
  };

  return (
    <div>
      <div className="block-top text-center ">
        <div className="container-xl"></div>
      </div>
      <div className="block-middle ">
        <div className="text-center">
          <h2 className="px-4">
            Situazione aggiornata al{" "}
            <span className="main-color bold">
              {new Date(authContext.userProfile.updatedAt).toLocaleDateString(
                "it-IT"
              )}
            </span>
          </h2>
          <div className="row wow fadeInUp">
            <div className="col-12 text-center">
              <div className="mt-30 ">
                <form id="form_xx">
                  <div className="pb-15">
                    <p className="font-ml secondary-color ">
                      In questa area puoi verificare lo stato generale del tuo
                      fatturato acquisti ed il totale dei punti maturati.
                      <br />I fatturati e i rispettivi punti saranno spendibili
                      salvo buon fine della verifica della correttezza di quanto
                      fatturato.
                      <br /> <br />
                      <strong>
                        Il totale punti maturati è la somma dei punti generata
                        dai fatturati arrotondati.
                      </strong>
                    </p>
                    <div className="d-flex justify-content-around">
                      <Link to={manlevaPDF} target="_blank" rel="noreferrer">
                        Download modello richiesta premio
                      </Link>
                    </div>
                  </div>
                  <div className="third-bg-color pt-3">
                    <div className="container-xl AreaPersonaleCliente">
                      <div className="row justify-content-center text-center ">
                        <div className="col-12 col-md-10 col-xl-9 mb-2 ">
                          <div className="box-soglia b-radius-0 box-border-secondary card mb-4">
                            <div className="row align-items-center justify-content-center">
                              <div className="col-12 col-md-2">
                                <img
                                  className="max-width  "
                                  src={sogliaSVG}
                                  alt="svg soglia punti"
                                />
                              </div>
                              <div className="col-11 col-md-10">
                                <div className="row align-items-center">
                                  <div className="col-12 col-md-3 p-md-0 text-center text-md-center">
                                    <div
                                      className={`${
                                        percentageToMinRevenue >= 0
                                          ? "main-color"
                                          : "red"
                                      } bold py-2`}
                                    >
                                      {percentageToMinRevenue.toLocaleString(
                                        "it-IT",
                                        { style: "percent" }
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-9">
                                    <div className="secondary-color py-3 text-center text-md-start">
                                      % vs la soglia minima di 15.000 €
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="box-fatturato b-radius-0 box-border-secondary card mb-4">
                            <div className="row align-items-center justify-content-center">
                              <div className="col-12 col-md-2">
                                <img
                                  className="max-width  "
                                  src={fatturatoSVG}
                                  alt="svg fatturato"
                                />
                              </div>
                              <div className="col-12 col-md-10">
                                <div className="row align-items-center">
                                  <div className="col-12 col-md-3 p-md-0 text-center text-md-center">
                                    <div className="main-color bold py-2">
                                      {parseFloat(
                                        authContext.userProfile.revenue
                                      ).toLocaleString("it-IT", {
                                        style: "currency",
                                        currency: "EUR",
                                      })}
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-9">
                                    <div className="secondary-color py-3 text-center text-md-start relative">
                                      fatturato totale acquisti raggiunto <br />{" "}
                                      {Object.keys(aggregatedRevenuesObj)
                                        .length > 0 && (
                                        <em className="font-s">
                                          (premi "+" per visualizzare il
                                          dettaglio per fornitore)
                                        </em>
                                      )}
                                      {Object.keys(aggregatedRevenuesObj)
                                        .length > 0 && (
                                        <div
                                          id="vediDettagliFatturato"
                                          className="align-right pointer main-color bold py-2 block-overlay font-m"
                                          onClick={
                                            setAreRevenueDetailsOpenClickHandler
                                          }
                                        >
                                          +
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {areRevenueDetailsOpen && (
                              <div id="dettagliFatturato">
                                <div className="row align-items-center justify-content-end">
                                  <div className="col-12 col-md-9">
                                    {Object.keys(
                                      aggregatedRevenuesObj ?? []
                                    ).map((supplier) => {
                                      const revenue = aggregatedRevenuesObj[
                                        supplier
                                      ].toLocaleString("it-IT", {
                                        style: "currency",
                                        currency: "EUR",
                                      });
                                      return (
                                        <div
                                          key={supplier}
                                          className="row align-items-center justify-content-end top-border-secondary"
                                        >
                                          <div className="col-12 col-md-3 p-md-0 text-center text-md-center">
                                            <div className="main-color bold py-2">
                                              {revenue}
                                            </div>
                                          </div>
                                          <div className="col-12 col-md-9">
                                            <div className="font-s secondary-color py-3 text-center text-md-start">
                                              Di cui fornitore "{supplier}"
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                  <div className="col-12 col-md-1"></div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="row align-items-center justify-content-between">
                            <div className="col-12 col-md-6 text-center">
                              <div className="box-punti-maturati b-radius-0 box-border-main card mb-4 pb-4">
                                <img
                                  className="max-width  py-2"
                                  src={puntiSVG}
                                  alt="svg punti"
                                />
                                <div className="py-2">
                                  <div className="main-color bold py-1">
                                    TOTALE PUNTI MATURATI
                                  </div>
                                  <div className="main-color bold py-1">
                                    {authContext.userProfile.pointsSpot +
                                      authContext.userProfile.pointsRevenue +
                                      authContext.userProfile.pointsBonus}
                                  </div>
                                </div>
                                <div className="py-2">
                                  <div className="secondary-color py-1 ">
                                    di cui con il ft. acquisti
                                  </div>
                                  <div className="secondary-color py-1 bold">
                                    {authContext.userProfile.pointsRevenue ?? 0}
                                  </div>
                                </div>
                                <div className="py-2">
                                  <div className="secondary-color py-1 ">
                                    di cui con azione promo
                                  </div>
                                  <div className="secondary-color py-1 bold">
                                    {authContext.userProfile.pointsSpot ?? 0}
                                  </div>
                                </div>
                                <div className="py-2">
                                  <div className="secondary-color py-1 ">
                                    di cui bonus SI SUPPLY
                                  </div>
                                  <div className="secondary-color py-1 bold ">
                                    {authContext.userProfile.pointsBonus ?? 0}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 text-center">
                              <div className="box-punti-spesi b-radius-0 box-border-main card mb-4">
                                <img
                                  className="max-width  py-2"
                                  src={spesiSVG}
                                  alt="svg punti spesi"
                                />
                                <div className="py-2">
                                  <div className="main-color bold pb-1">
                                    TOTALE PUNTI SPESI
                                  </div>
                                  <div className="main-color bold pb-1 pt-2">
                                    {authContext.userProfile.pointsUsed ?? 0}
                                  </div>
                                </div>
                              </div>
                              <div className="box-punti-residui b-radius-0 box-border-main card mb-4">
                                <img
                                  className="max-width  py-2"
                                  src={residuiSVG}
                                  alt="svg punti residui"
                                />
                                <div className="py-2">
                                  <div className="main-color bold pb-1">
                                    TOTALE PUNTI SPENDIBILI
                                  </div>
                                  <div className="main-color bold pb-1 pt-2">
                                    {authContext.userProfile.pointsAvailable ??
                                      0}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12 col-md-6 text-center">
                          <input
                            id="attivaPremi"
                            className="btn button-standard btn-radius mb-4 attiva-premi"
                            type="button"
                            value="CONSULTA PREMI RICHIESTI"
                            onClick={getRequestsDetailsClickHandler}
                          />
                        </div>
                        <div className="col-12 col-md-6 text-center">
                          <input
                            id="attivaPremi"
                            className="btn button-standard btn-radius mb-4 attiva-premi"
                            type="button"
                            value="RICHIEDI PREMIO"
                            onClick={() => {
                              navigate("/catalogo");
                            }}
                          />
                        </div>
                      </div>
                      {authContext.isLoadingProductRequest && (
                        <p className="pb-4">Caricamento richieste premi...</p>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {authContext.productRequests.length > 0 && (
        <div className="block-bottom third-bg-color">
          <div className="body-bg-color" id="tabPremi">
            <div className="container-xl">
              <div className="row wow fadeInUp">
                <div className="col-12 text-center mt-1 mb-3">
                  <div id="Table-Container" className="p-1 ">
                    <div
                      id="Tabella-Dati"
                      className="table-responsive table-wrapper tabella-dati"
                    >
                      <table className="table m-0">
                        <thead>
                          <tr>
                            <th scope="col" className="">
                              <div className="tab-head py-1">
                                <span className="secondary-color font-s">
                                  PREMIO
                                </span>
                              </div>
                            </th>
                            <th scope="col" className="">
                              <div className="tab-head py-1">
                                <span className="secondary-color font-s">
                                  PUNTI
                                </span>
                              </div>
                            </th>
                            <th scope="col" className="">
                              <div className="tab-head py-1">
                                <span className="secondary-color font-s">
                                  DATA{" "}
                                  <span className="hidden-xxs">
                                    RICHIESTA PREMIO
                                  </span>
                                </span>
                              </div>
                            </th>
                            <th scope="col" className="">
                              <div className="tab-head py-1">
                                <span className="secondary-color font-s">
                                  INDIRIZZO{" "}
                                  <span className="">DESTINAZIONE</span>
                                </span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {authContext.productRequests.map((productRequest) => {
                            return (
                              <ProductRequestTile
                                productId={productRequest.productId}
                                productCost={productRequest.productCost}
                                createdAt={productRequest.createdAt}
                                address={productRequest.address}
                                houseNumber={productRequest.houseNumber}
                                city={productRequest.city}
                                email={authContext.userProfile.email}
                              />
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {authContext.errorMessage && (
            <div className="container-xl">
              <div className="row wow fadeInUp">
                <div className="col-12 text-center mt-1 mb-3">
                  <div id="user_messages">{authContext.errorMessage}</div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default UserProfilePage;
