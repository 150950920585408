import { Routes, Route } from "react-router-dom";
import { useAuthContext } from "./application/auth/hooks/useAuthContext";
import { AdminContextProvider } from "./application/admin/contexts/admin-context";
import SpinnerContainer from "./presentation/common/components/SpinnerContainer/SpinnerContainer";
import ProtectedRoute from "./presentation/auth/components/ProtectedRoute/ProtectedRoute";

// macro
import Header from "./presentation/common/components/Header/Header";
import Jumbo from "./presentation/common/components/Jumbo/Jumbo";
import Main from "./presentation/common/components/Main/Main";
import Footer from "./presentation/common/components/Footer/Footer";

// pages
import LoginPage from "./presentation/auth/pages/LoginPage/LoginPage";
import UpdateUserPage from "./presentation/auth/pages/UpdateUserPage/UpdateUserPage";
import IntroPage from "./presentation/common/pages/IntroPage/IntroPage";
import CataloguePage from "./presentation/products/pages/CataloguePage/CataloguePage";
import UserProfilePage from "./presentation/auth/pages/UserProfilePage/UserProfilePage";
import AdminProfilePage from "./presentation/auth/pages/AdminProfilePage/AdminProfilePage";
import PasswordAskResetPage from "./presentation/auth/pages/PasswordAskResetPage/PasswordAskResetPage";
import PasswordInsertNewPage from "./presentation/auth/pages/PasswordInsertNewPage/PasswordInsertNewPage";
import PasswordResetSuccessPage from "./presentation/auth/pages/PasswordResetSuccessPage/PasswordResetSuccessPage";
import PasswordEmailSentPage from "./presentation/auth/pages/PasswordEmailSentPage/PasswordEmailSentPage";
import OnlineThankYouPage from "./presentation/products/pages/OnlineThankYouPage/OnlineThankYouPage";
import PhysicalRequestPage from "./presentation/products/pages/PhysicalRequestPage/PhysicalRequestPage";
import PhysicalThankYouPage from "./presentation/products/pages/PhysicalThankYouPage/PhysicalThankYouPage";
import NotFoundPage from "./presentation/common/pages/NotFoundPage/NotFoundPage";
import CookiePage from "./presentation/cookie/pages/CookiePage/CookiePage";
//css
import "./assets/css/core/bootstrap.min.css";
import "./assets/css/core/animate.css";
import "./assets/css/core/core.css";
import "./assets/css/alter/alter.css";
import "./assets/css/alter/colors.css";
import { useEffect } from "react";

const domainGroupId = "c95d047d-669d-47a5-9e29-5302a3f2481f";

const App = () => {
  const authContext = useAuthContext();

  useEffect(() => {
    const script = document.createElement("script");
    script.setAttribute("id", "CookieBot");
    script.setAttribute("src", "https://consent.cookiebot.com/uc.js");
    script.setAttribute("data-cbid", domainGroupId);
    script.setAttribute("data-blockingmode", "auto");
    script.setAttribute("type", "text/javascript");
    const head = document.querySelector("html > head");
    head.insertBefore(script, head.firstChild);
  }, []);

  return authContext.isLoading ? (
    <SpinnerContainer height={"100vh"} width={"100%"} />
  ) : (
    <div className="App wow page-template-default page template-slider color-custom style-default button-default layout-full-width no-content-padding header-classic minimalist-header-no ab-hide subheader-both-center menu-link-color menuo-right menuo-no-borders mobile-tb-center mobile-side-slide mobile-mini-mr-ll be-reg-2099">
      <div id="Wrapper">
        <Header />
        <Jumbo />
        <Main>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route
              path="/profilo/aggiorna"
              element={
                <ProtectedRoute userIsLoggedIn={authContext.isLoggedIn}>
                  <UpdateUserPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profilo"
              element={
                <ProtectedRoute userIsLoggedIn={authContext.isLoggedIn}>
                  {authContext.userProfile?.role === "admin" ? (
                    <AdminContextProvider>
                      <AdminProfilePage />
                    </AdminContextProvider>
                  ) : (
                    <UserProfilePage />
                  )}
                </ProtectedRoute>
              }
            />
            <Route
              path="/password/reset/richiesta"
              element={<PasswordAskResetPage />}
            />
            <Route
              path="/password/reset/email"
              element={<PasswordEmailSentPage />}
            />
            <Route
              path="/password/reset/new"
              element={<PasswordInsertNewPage />}
            />
            <Route
              path="/password/reset/success"
              element={<PasswordResetSuccessPage />}
            />
            <Route path="/presentazione" element={<IntroPage />} />
            <Route path="/cookie" element={<CookiePage />} />

            <Route path="/catalogo" element={<CataloguePage />} />

            <Route
              path="/spedizione/online/conferma"
              element={
                <ProtectedRoute userIsLoggedIn={authContext.isLoggedIn}>
                  <OnlineThankYouPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/spedizione/fisica"
              element={
                <ProtectedRoute userIsLoggedIn={authContext.isLoggedIn}>
                  <PhysicalRequestPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/spedizione/fisica/conferma"
              element={
                <ProtectedRoute userIsLoggedIn={authContext.isLoggedIn}>
                  <PhysicalThankYouPage />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Main>
        <Footer />
      </div>
    </div>
  );
};
export default App;
