import React from "react";
import { useProductsContext } from "../../../../application/products/hooks/useProductsContext";
import PhysicalRequestForm from "../../components/PhysicalRequestForm/PhysicalRequestForm";
import SpinnerContainer from "./../../../common/components/SpinnerContainer/SpinnerContainer";

const PhysicalRequestPage = () => {
  const productsContext = useProductsContext();

  return (
    <div>
      <div className="block-top text-center main-bg-color ">
        <div className="container-fluid"></div>
      </div>
      <div className="block-middle container-xl">
        <div className="row wow fadeInUp">
          <div className="col-12 text-center mt-5 mb-25">
            <div id="box-other-address">
              {productsContext.isLoading ? (
                <SpinnerContainer height={"500px"} width={"100%"} />
              ) : (
                <PhysicalRequestForm />
              )}
            </div>
          </div>
        </div>
      </div>
      {productsContext.errorMessage && (
        <div className="block-bottom ">
          <div className="container-xl">
            <div className="row wow fadeInUp">
              <div className="col-12 text-center mt-1 mb-3">
                <div id="user_messages">{productsContext.errorMessage}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PhysicalRequestPage;
