import { React, useState } from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../../../application/auth/hooks/useAuthContext";
import { useGeoUtilsContext } from "../../../../application/utils/hooks/useGeoUtilsContext";

// assets
import privacyPDF from "./../../../../assets/documents/privacy.pdf";
import recontactPDF from "./../../../../assets/documents/privacy-recontact.pdf";
import manlevaPDF from "./../../../../assets/documents/manleva.pdf";

const UpdateForm = () => {
  const authContext = useAuthContext();
  const geoUtilsContext = useGeoUtilsContext();

  const [userUpdateInfo, setUserUpdateInfo] = useState({
    address: authContext.userProfile.address ?? "",
    houseNumber: authContext.userProfile.houseNumber ?? "",
    zip: authContext.userProfile.zip ?? "",
    provinceId: authContext.userProfile.provinceId ?? "",
    cityId: authContext.userProfile.cityId ?? "",
    phoneNumber: authContext.userProfile.phoneNumber ?? "",
    referentFirstName: authContext.userProfile.referentFirstName ?? "",
    referentLastName: authContext.userProfile.referentLastName ?? "",
    password: "",
    privacy: false,
    privacyRecontact: false,
  });

  const provinceSelectOnChangeHandler = (event) => {
    geoUtilsContext.setSelectedProvince(event.target.value);
  };

  const userUpdateInfoChangeHandler = (event) => {
    setUserUpdateInfo({
      ...userUpdateInfo,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    authContext.updateUserProfile({
      ...userUpdateInfo,
      provinceId: geoUtilsContext.selectedProvince,
    });
  };

  return (
    <form id="form_register" onSubmit={submitHandler}>
      <div className="container  ">
        <div className="row justify-content-center text-center">
          <div className="col-12 col-md-10 col-xl-7">
            <div className="row mt-30 text-center justify-content-center">
              <div className="col-12 col-sm-6 ">
                <input
                  type="text"
                  id="user_ragione_sociale"
                  value={authContext.userProfile.businessName}
                  placeholder="Ragione sociale punto vendita"
                  autoComplete="off"
                  className="inputs"
                  maxLength="100"
                  disabled
                />
              </div>
              <div className="col-12 col-sm-6 ">
                <div className="row">
                  <div className="col-sm-8">
                    <input
                      type="text"
                      id="user_address"
                      name="address"
                      value={userUpdateInfo.address}
                      onChange={userUpdateInfoChangeHandler}
                      placeholder="Indirizzo*"
                      autoComplete="off"
                      maxLength="100"
                      className="inputs"
                      required
                    />
                  </div>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      id="user_house_number"
                      name="houseNumber"
                      value={userUpdateInfo.houseNumber}
                      onChange={userUpdateInfoChangeHandler}
                      placeholder="N. civico*"
                      autoComplete="off"
                      maxLength="5"
                      className="inputs"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row  text-center justify-content-center">
              <div className="col-12 col-sm-6 ">
                <input
                  type="text"
                  id="user_zip_code"
                  name="zip"
                  value={userUpdateInfo.zip}
                  onChange={userUpdateInfoChangeHandler}
                  placeholder="CAP*"
                  autoComplete="off"
                  maxLength="5"
                  className="inputs"
                  required
                />
              </div>
              <div className="col-12 col-sm-6 ">
                <select
                  id="user_province"
                  name="provinceId"
                  value={geoUtilsContext.selectedProvince}
                  onChange={provinceSelectOnChangeHandler}
                  className="province inputs"
                  required
                >
                  <option value="" className="lt" hidden>
                    Provincia*
                  </option>
                  {geoUtilsContext.provinces.map((province) => (
                    <option
                      key={province.provinceId}
                      value={province.provinceId}
                      className="lt"
                    >
                      {province.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row  text-center justify-content-center">
              {geoUtilsContext.cities.length > 0 && (
                <div className="col-12 col-sm-6 ">
                  <select
                    name="cityId"
                    id="user_city"
                    className="province inputs"
                    value={userUpdateInfo.cityId}
                    onChange={userUpdateInfoChangeHandler}
                    required
                  >
                    <option value="" className="lt" hidden>
                      Località*
                    </option>
                    {geoUtilsContext.cities.map((city) => (
                      <option
                        key={city.cityId}
                        value={city.cityId}
                        className="lt"
                      >
                        {city.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div className="col-12 col-sm-6 ">
                <input
                  type="text"
                  id="user_phone_number"
                  name="phoneNumber"
                  value={userUpdateInfo.phoneNumber}
                  onChange={userUpdateInfoChangeHandler}
                  maxLength="15"
                  placeholder="Numero telefonico"
                  className="inputs"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="row text-center justify-content-center">
              <div className="col-12 col-sm-6 ">
                <input
                  type="text"
                  id="user_first_name"
                  name="referentFirstName"
                  value={userUpdateInfo.referentFirstName}
                  onChange={userUpdateInfoChangeHandler}
                  placeholder="Nome incaricato*"
                  className="inputs"
                  maxLength="50"
                  autoComplete="off"
                  required
                />
              </div>
              <div className="col-12 col-sm-6 ">
                <input
                  type="text"
                  id="user_last_name"
                  name="referentLastName"
                  value={userUpdateInfo.referentLastName}
                  onChange={userUpdateInfoChangeHandler}
                  placeholder="Cognome incaricato*"
                  className="inputs"
                  maxLength="50"
                  autoComplete="off"
                  required
                />
              </div>
            </div>
            <div className="row  text-center justify-content-center">
              <div className="col-12 col-sm-6 ">
                <input
                  type="text"
                  id="user_email"
                  value={authContext.userProfile.email}
                  placeholder="E-mail"
                  maxLength="50"
                  className="inputs"
                  autoComplete="off"
                  disabled
                />
              </div>
              <div className="col-12 col-sm-6 ">
                <input
                  type="password"
                  id="user_password"
                  name="password"
                  value={userUpdateInfo.password}
                  onChange={userUpdateInfoChangeHandler}
                  placeholder="Password*"
                  maxLength="25"
                  className="inputs"
                  autoComplete="off"
                  required
                />
              </div>
            </div>
            <div className="row justify-content-center ">
              <div className="col-12 col-sm-6 align-left ">
                <div className="form-check flex align-items-center">
                  <input
                    type="checkbox"
                    id="privacy"
                    name="privacy"
                    value={userUpdateInfo.privacy}
                    onChange={userUpdateInfoChangeHandler}
                    className="form-check-input check-privacy required"
                    required
                  />
                  <label
                    className="form-check-label check-label font-xs ms-2"
                    htmlFor="privacy"
                  >
                    Accetto l'
                    <Link to={privacyPDF} target="_blank" className="underline">
                      informativa Privacy*
                    </Link>
                  </label>
                </div>
              </div>
              <div className="col-12 col-sm-6 align-left ">
                <div className="form-check flex align-items-center">
                  <input
                    type="checkbox"
                    id="promozioni"
                    name="privacyRecontact"
                    value={userUpdateInfo.privacyRecontact}
                    onChange={userUpdateInfoChangeHandler}
                    className="form-check-input check-promozioni"
                  />
                  <label
                    className="form-check-label check-label font-xs ms-2"
                    htmlFor="promozioni"
                  >
                    Accetto la{" "}
                    <Link
                      to={recontactPDF}
                      target="_blank"
                      className="underline"
                    >
                      Privacy Ricontatto
                    </Link>
                  </label>
                </div>
              </div>
              <div className="col-12 align-left mt-4">
                <div className="form-check flex align-items-center">
                  <input
                    type="checkbox"
                    id="promozioni"
                    name="privacyRecontact"
                    value={userUpdateInfo.privacyRecontact}
                    onChange={userUpdateInfoChangeHandler}
                    className="form-check-input check-promozioni"
                    required
                  />
                  <label
                    className="form-check-label check-label font-xs ms-2"
                    htmlFor="promozioni"
                  >
                    Dichiaro di aver preso visione della manleva*.{" "}
                    <Link to={manlevaPDF} target="_blank" className="underline">
                      Scarica il modello.
                    </Link>
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 text-center mt-35">
                <input
                  className="btn button-standard btn-radius "
                  type="submit"
                  id="form_update_submit"
                  value="COMPLETA"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default UpdateForm;
