import React from "react";

const PasswordEmailSentPage = () => {
  return (
    <main className="main-container ">
      <div className="">
        <div className="block-top text-center main-bg-color ">
          <div className="container-fluid"></div>
        </div>
        <div className="block-middle container-xl">
          <div className="row justify-content-center wow fadeInUp">
            <div className="col-12 col-sm-12 text-center">
              <p className="font-ml secondary-color mt-50">
                Ti abbiamo inviato una e-mail per il reset della tua password.
                <br />
                <br />
                N.B.: ricordati di verificare anche la casella di posta dello
                spam{" "}
                <span className="hide-md">
                  <br />
                </span>
                e delle offerte nel caso le nostre e-mail siano finite lì.
              </p>
            </div>
          </div>
        </div>

        <div className="block-bottom ">
          <div className="container-xl">
            <div className="row wow fadeInUp">
              <div className="col-12 text-center my-3">
                <div id="user_messages"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default PasswordEmailSentPage;
