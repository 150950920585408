import { createContext, useState, useEffect } from "react";
import {
  assignExtraPoints,
  getTotalUsersInfo,
  searchUserById,
  getAllUsersProfileJSON,
  getAllUsersRequestsJSON,
} from "../../../services/admin/admin.js";
import { useAuthContext } from "../../auth/hooks/useAuthContext.js";
import * as XLSX from "xlsx";

const AdminContext = createContext();

export const AdminContextProvider = (props) => {
  const authContext = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchedUser, setSearchedUser] = useState({});
  const [totalUsersInfo, setTotalUsersInfo] = useState({});

  useEffect(() => {
    getTotalUsersInfoHandler();
  }, []);

  function getTotalUsersInfoHandler() {
    setIsLoading(true);
    getTotalUsersInfo(authContext.token).then((totalUsersResponse) => {
      if (
        totalUsersResponse.success &&
        totalUsersResponse.count > 0 &&
        totalUsersResponse.codes.length
      ) {
        setTotalUsersInfo({ ...totalUsersResponse });
      } else {
        setErrorMessage("Conteggio utenti non riuscito.");
      }
      setIsLoading(false);
    });
  }

  const searchUserHandler = (userId) => {
    setIsSearching(true);
    searchUserById(authContext.token, userId).then((userResponse) => {
      if (
        userResponse.success &&
        Object.keys(userResponse.userProfile).length
      ) {
        setSearchedUser(userResponse.userProfile);
      } else {
        setErrorMessage("Utente non trovato.");
      }
      setIsSearching(false);
    });
  };

  const assignExtraPointsHandler = (pointsType, pointsToAdd) => {
    assignExtraPoints(
      authContext.token,
      searchedUser.userId,
      pointsType,
      pointsToAdd
    ).then((userResponse) => {
      if (
        userResponse.success &&
        Object.keys(userResponse.userUpdated).length
      ) {
        setSearchedUser(userResponse.userUpdated);
      } else {
        setErrorMessage("Assegnazione punti non riuscita.");
      }
    });
  };

  const downloadAllUsersProfilesXLSHandler = () => {
    getAllUsersProfileJSON(authContext.token).then((response) => {
      if (response.success && response.json) {
        const worksheet = XLSX.utils.json_to_sheet(response.json);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Utenti");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "SI-Supply-Utenti.xlsx");
      } else {
        setErrorMessage("Download XLS non riuscito.");
      }
    });
  };

  const downloadAllUsersRequestXLSHandler = () => {
    getAllUsersRequestsJSON(authContext.token).then((response) => {
      if (response.success && response.json) {
        const worksheet = XLSX.utils.json_to_sheet(response.json);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Richieste-prodotti");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "SI-Supply-Richieste-prodotti.xlsx");
      } else {
        setErrorMessage("Nessuna richiesta premio disponibile.");
      }
    });
  };

  const contextValue = {
    isLoading: isLoading,
    isSearching: isSearching,
    errorMessage: errorMessage,
    searchedUser: searchedUser,
    searchUser: searchUserHandler,
    assignExtraPoints: assignExtraPointsHandler,
    totalUsersInfo: totalUsersInfo,
    downloadAllUsersProfilesXLS: downloadAllUsersProfilesXLSHandler,
    downloadAllUsersRequestsXLS: downloadAllUsersRequestXLSHandler,
  };

  return (
    <AdminContext.Provider value={contextValue}>
      {props.children}
    </AdminContext.Provider>
  );
};

export default AdminContext;
