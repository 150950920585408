import React from "react";

const NotFoundPage = () => {
  return (
    <main className="main-container ">
      <div className="">
        <div className="block-top text-center main-bg-color ">
          <div className="container-fluid"></div>
        </div>
        <div className="block-middle container-xl">
          <div className="row justify-content-center wow fadeInUp">
            <div className="col-12 col-sm-12 text-center">
              <h2 className="mb-4 mt-3 h1">404</h2>
              <p className="secondary-color h2">Pagina non trovata.</p>
            </div>
          </div>
        </div>
        <div className="block-bottom ">
          <div className="container-xl">
            <div className="row wow fadeInUp">
              <div className="col-12 text-center my-3">
                <div id="user_messages"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default NotFoundPage;
