import { React, useState } from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../../../application/auth/hooks/useAuthContext";

const LoginForm = () => {
  const authContext = useAuthContext();

  // email input
  const [enteredEmail, setEnteredEmail] = useState();
  const emailInputChangeHandler = (event) => {
    setEnteredEmail(event.target.value);
  };

  // password input
  const [enteredPassword, setEnteredPassword] = useState();
  const passwordInputChangeHandler = (event) => {
    setEnteredPassword(event.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    authContext.login(enteredEmail, enteredPassword);
  };

  return (
    <form id="login-form" onSubmit={submitHandler}>
      <div className="row justify-content-center">
        <div className="col-11 col-sm-8 col-md-5 col-lg-4 col-xl-3">
          <input
            type="text"
            id="user_name"
            className="inputs"
            required
            placeholder="User (e-mail)"
            autoComplete="off"
            maxLength="50"
            onChange={emailInputChangeHandler}
          />
          <input
            type="password"
            id="user_password"
            className="inputs"
            required
            placeholder="Password"
            maxLength="50"
            autoComplete="off"
            onChange={passwordInputChangeHandler}
          />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-11 col-sm-8 col-md-5 col-lg-4 col-xl-3">
          <input
            className="btn button-standard btn-radius align-middle bold w-100"
            type="submit"
            id="form_update_submit"
            value="ACCEDI"
          />
          <p className="mt-4 mb-0 font-xs third-color">
            Per effettuare il reset password
            <br />
            <Link
              to="/password/reset/richiesta"
              className="underline third-color"
            >
              Clicca qui
            </Link>
          </p>
        </div>
      </div>
    </form>
  );
};

export default LoginForm;
