import React, { useEffect } from "react";

const CookieSection = () => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://consent.cookiebot.com/c95d047d-669d-47a5-9e29-5302a3f2481f/cd.js";
    script.async = true;
    script.id = "CookieDeclaration";
    script.type = "text/javascript"

    document.getElementById("cookieSection").appendChild(script);
  }, []);

  return (
    <div className="col-12 text-center mt-5 mb-25" id="cookieSection"></div>
  );
};

export default CookieSection;
