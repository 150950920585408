import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getProductCategories,
  getProducts,
  requestProduct,
} from "../../../services/products/products";
import { useAuthContext } from "../../auth/hooks/useAuthContext.js";

const ProductsContext = createContext();

export const ProductsContextProvider = (props) => {
  const navigate = useNavigate();
  const authContext = useAuthContext();

  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const [productCategories, setProductCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");

  useEffect(() => {
    if (productCategories.length === 0) {
      getProductCategoriesHandler();
    }
    if (products.length === 0) {
      getProductsHandler();
    }
  }, []);

  const getProductCategoriesHandler = () => {
    setIsLoading(true);

    getProductCategories().then((productCategoriesResponse) => {
      if (
        productCategoriesResponse.success &&
        productCategoriesResponse.productCategories.length
      ) {
        setErrorMessage("");
        setProductCategories(productCategoriesResponse.productCategories);
      } else {
        setErrorMessage("Categorie prodotti non disponibili.");
      }
      // setIsLoading(false);
    });
  };

  const getProductsHandler = () => {
    setIsLoading(true);

    getProducts().then((productsResponse) => {
      if (productsResponse.success && productsResponse.products.length) {
        setErrorMessage("");
        setProducts(
          productsResponse.products.sort(
            (a, b) => parseFloat(a.cost) - parseFloat(b.cost)
          )
        );
      } else {
        setErrorMessage("Catalogo prodotti non disponibile.");
      }
      setIsLoading(false);
    });
  };

  const setSelectedProductHandler = (product) => {
    setIsLoading(true);
    setSelectedProduct(product);
    setIsLoading(false);
  };

  const requestProductHandler = (product, shippingInfo) => {
    if (authContext.userProfile.pointsAvailable >= product.cost) {
      setIsLoading(true);
      setErrorMessage("");
      requestProduct(authContext.token, product, shippingInfo).then(
        (requestProductResponse) => {
          if (
            requestProductResponse.success &&
            requestProductResponse.productId
          ) {
            setErrorMessage("");
            authContext.refreshUserProfile();
            if (requestProduct.productType === "online") {
              navigate("/spedizione/online/conferma", { replace: true });
            } else {
              navigate("/spedizione/fisica/conferma", { replace: true });
            }
          } else {
            setErrorMessage("Richiesta prodotto non andata a buon fine.");
          }
          setIsLoading(false);
        }
      );
    } else {
      setErrorMessage("Non hai abbastanza punti per richiedere il premio.");
    }
  };

  const contextValue = {
    isLoading: isLoading,
    errorMessage: errorMessage,
    productCategories: productCategories,
    products: products,
    selectedProduct: selectedProduct,
    setSelectedProduct: setSelectedProductHandler,
    requestProduct: requestProductHandler,
  };

  return (
    <ProductsContext.Provider value={contextValue}>
      {props.children}
    </ProductsContext.Provider>
  );
};

export default ProductsContext;
