import React from "react";
import { Link } from "react-router-dom";

const PasswordResetSuccessPage = () => {
  return (
    <main className="main-container ">
      <div className="">
        <div className="block-top text-center main-bg-color ">
          <div className="container-fluid"></div>
        </div>
        <div className="block-middle container-xl">
          <div className="row justify-content-center wow fadeInUp">
            <div className="col-12 col-sm-12 text-center">
              <p className="font-ml secondary-color mt-50">
                La tua password è stata resettata con successo. <br />
                Vai al{" "}
                <Link className="underline secondary-color" to="/">
                  login
                </Link>{" "}
                ed accedi al portale.
              </p>
            </div>
          </div>
        </div>

        <div className="block-bottom ">
          <div className="container-xl">
            <div className="row wow fadeInUp">
              <div className="col-12 text-center my-3">
                <div id="user_messages"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default PasswordResetSuccessPage;
