import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../../application/auth/hooks/useAuthContext";
import { useProductsContext } from "../../../../application/products/hooks/useProductsContext";
import Swal from "sweetalert2";
import FsLightbox from "fslightbox-react";
import StyledToolTip from "../../../common/components/StyledToolTip/StyledToolTip";

const ProductCard = (product) => {
  const productsContext = useProductsContext();
  const authContext = useAuthContext();
  const navigate = useNavigate();

  const [productImage, setProductImage] = useState("");
  import(`./../../../../assets/images/products/${product.image}`).then(
    (image) => setProductImage(image.default)
  );

  const [isImageOpen, setIsImageOpen] = useState(false);

  const isImageOpenChangeHandler = () => {
    setIsImageOpen((prev) => !prev);
  };

  const requestProductHandler = () => {
    requestProduct(product);
  };

  function requestProduct(product) {
    Swal.fire({
      title: "",
      text: `Vuoi procedere con la richiesta di "${product.name.toLowerCase()}"?`,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "SI, voglio procedere",
      cancelButtonText: "NO, voglio ripensarci",
    }).then((response) => {
      if (response.isConfirmed) {
        productsContext.setSelectedProduct(product);
        if (product.type === "physical") {
          navigate("/spedizione/fisica");
        } else {
          productsContext.requestProduct(product);
        }
      }
    });
  }

  return (
    <div className={`col-12 col-md-6 col-xl-4 mb-2 mtn-50 ${product.hide}`}>
      <div className="box-premio card">
        <h2 className="font-ml main-color pb-1 bold d-block">
          {product.cost ?? ""}
        </h2>
        <p className="bold secondary-color font-s mt-2">{product.name ?? ""}</p>
        <div className="text-center">
          <div className="ratio ratio-4x3 mb-4">
            <img
              className="obj-fit-contain"
              src={productImage}
              alt={product.name}
            />
          </div>
          <div className="container" style={{ borderTop: "2px solid #2670AD" }}>
            <div className="row">
              <div className="col-6 d-none d-md-block">
                <p
                  className="readmore font-xs mb-2 mt-3 pointer secondary-color"
                  onClick={isImageOpenChangeHandler}
                >
                  Zoom Image
                </p>
                <FsLightbox
                  type="image"
                  sources={[productImage]}
                  toggler={isImageOpen}
                />
              </div>
              <div className="col-12 d-md-none">
                <p className="secondary-color font-s my-2">
                  {product.description ?? ""}
                </p>
              </div>
              <div className="col-6 d-none d-md-block">
                <StyledToolTip
                  title={
                    <React.Fragment>
                      <p className="secondary-color font-s my-2">
                        {product.description ?? ""}
                      </p>
                    </React.Fragment>
                  }
                >
                  <p className="readmore font-xs mb-2 mt-3 pointer secondary-color">
                    Description
                  </p>
                </StyledToolTip>
              </div>
            </div>
          </div>
        </div>
        {Object.keys(authContext.userProfile).length ? (
          <input
            className="btn-richiestapremio btn button-standard btn-radius mt-1"
            type="button"
            value="RICHIEDI"
            disabled={
              product.cost > authContext.userProfile.pointsAvailable
                ? true
                : false
            }
            onClick={requestProductHandler}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ProductCard;
