import { apiResponseValidator } from "../api-response-validator";

export const searchUserById = async (token, userId) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL +
        `/admin/user?userId=${encodeURIComponent(userId)}`,
      // `https://9ydsb15ld4.execute-api.eu-central-1.amazonaws.com/admin/user?userId=${userId}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const responseValidation = apiResponseValidator(response);
    if (!responseValidation.success) return responseValidation;

    const data = await response.json();
    if (data && data.Item && Object.keys(data.Item).length) {
      return { success: true, userProfile: data.Item };
    }
    return { success: false };
  } catch (error) {
    return {
      success: false,
      message: error.message ?? "network-products-searchuserbyid-fail",
    };
  }
};

export const assignExtraPoints = async (
  token,
  userIdToUpdate,
  pointsType,
  pointsToAdd
) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/admin/points",
      // "https://9ydsb15ld4.execute-api.eu-central-1.amazonaws.com/admin/points",
      {
        method: "POST",
        body: JSON.stringify({
          userIdToUpdate: userIdToUpdate,
          pointsType: pointsType,
          pointsToAdd: pointsToAdd,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const responseValidation = apiResponseValidator(response);
    if (!responseValidation.success) return responseValidation;

    const data = await response.json();
    if (data && data.success && Object.keys(data.Item).length) {
      return {
        success: true,
        userUpdated: data.Item,
      };
    }
    return {
      success: false,
    };
  } catch (error) {
    return {
      success: false,
      message: error.message ?? "network-admin-assignextrapoints-fail",
    };
  }
};

export const getTotalUsersInfo = async (token) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/admin/stats",
      // "https://9ydsb15ld4.execute-api.eu-central-1.amazonaws.com/admin/stats",
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const responseValidation = apiResponseValidator(response);
    if (!responseValidation.success) return responseValidation;

    const data = await response.json();
    if (data && data.Items.length && data.Count > 0) {
      return {
        success: true,
        count: data.Count,
        codes: data.Items,
        revenue: parseFloat(data.revenue).toFixed(2),
        points: data.points,
        pointsAvailable: data.pointsAvailable,
        pointsUsed: data.pointsUsed,
      };
    }
    return {
      success: false,
    };
  } catch (error) {
    return {
      success: false,
      message: error.message ?? "network-admin-gettotalusersinfo-fail",
    };
  }
};

export const getAllUsersProfileJSON = async (token) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/admin/export",
      // "https://9ydsb15ld4.execute-api.eu-central-1.amazonaws.com/admin/stats",
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const responseValidation = apiResponseValidator(response);
    if (!responseValidation.success) return responseValidation;

    const data = await response.json();
    if (data && data.Items.length) {
      return {
        success: true,
        json: data.Items,
      };
    }
    return {
      success: false,
    };
  } catch (error) {
    return {
      success: false,
      message: error.message ?? "network-admin-getallusersprofilejson-fail",
    };
  }
};

export const getAllUsersRequestsJSON = async (token) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/admin/requests",
      // "https://9ydsb15ld4.execute-api.eu-central-1.amazonaws.com/admin/stats",
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const responseValidation = apiResponseValidator(response);
    if (!responseValidation.success) return responseValidation;

    const data = await response.json();
    if (data && data.Items.length) {
      return {
        success: true,
        json: data.Items,
      };
    }
    return {
      success: false,
    };
  } catch (error) {
    return {
      success: false,
      message: error.message ?? "network-admin-getallusersrequestsjson-fail",
    };
  }
};
