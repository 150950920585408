import { useContext } from "react";
import ProductsContext from "./../contexts/products-context";

export const useProductsContext = () => {
  const context = useContext(ProductsContext);

  if (!context) {
    throw Error("useProductsContext must be used inside his context");
  }

  return context;
};
