import React, { useEffect, useState } from "react";
import { useProductsContext } from "./../../../../application/products/hooks/useProductsContext";
import ProductCard from "../ProductCard/ProductCard";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../../../application/auth/hooks/useAuthContext";

const CatalogueForm = () => {
  const productsContext = useProductsContext();
  const authContext = useAuthContext();

  const [selectedProductCategory, setSelectedProductCategory] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [productCardsToShow, setProductCardsToShow] = useState(6);

  useEffect(() => {
    setFilteredProducts(
      productsContext.products.filter((product) => {
        return (
          selectedProductCategory === product.categoryId ||
          selectedProductCategory === ""
        );
      })
    );
  }, [productsContext.products, selectedProductCategory, productCardsToShow]);

  const categorySelectOnChangeHandler = (event) => {
    setSelectedProductCategory(event.target.value);
    setProductCardsToShow(6);
  };

  const loadMoreProductClickHandler = () => {
    setProductCardsToShow((prev) => prev + 3);
  };

  return (
    <form id="form_xx">
      <div className="pb-50">
        <p className="font-ml secondary-color ">
          Questa è la selezione dei premi che è stata realizzata appositamente
          per questa iniziativa.
        </p>
        {Object.keys(authContext.userProfile).length > 0 ? (
          <p className="font-ml secondary-color ">
            Potrai utilizzare i punti a tua disposizione e richiedere i premi
            nei periodi indicati nel regolamento.
            <br />
            <br />
            Verifica il saldo punti nella tua{" "}
            <Link to="/profilo" className="secondary-color">
              area personale
            </Link>
            .
          </p>
        ) : null}
        <select
          id="tipo_premio"
          name="productCategoryId"
          value={selectedProductCategory}
          onChange={categorySelectOnChangeHandler}
          className="inputs tipo-premio"
          required
        >
          <option value="" className="lt">
            GENERALE
          </option>
          {productsContext.productCategories.map((productCategory) => (
            <option
              key={productCategory.name}
              value={productCategory.categoryId}
              className="lt"
            >
              {productCategory.name}
            </option>
          ))}
        </select>
      </div>
      <div className="third-bg-color mt-50">
        <div className="container-xl box-premi">
          <div className="row justify-content-start text-center ">
            {filteredProducts.map((product, index) => {
              return (
                <ProductCard
                  key={index}
                  productId={product.productId}
                  name={product.name}
                  cost={product.cost}
                  image={`${product.productId}.jpg`}
                  description={product.description}
                  type={product.productType}
                  hide={index + 1 > productCardsToShow ? "hide" : ""}
                />
              );
            })}
          </div>
          {productCardsToShow <= filteredProducts.length && (
            <input
              className="btn button-standard btn-radius mb-5"
              type="button"
              id="form_load_more"
              onClick={loadMoreProductClickHandler}
              value="CARICA ALTRI"
            />
          )}
        </div>
      </div>
    </form>
  );
};

export default CatalogueForm;
