import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useAuthContext } from "../../../../application/auth/hooks/useAuthContext";

import ImgLogoSVG from "./../../../../assets/images/alter/logo.svg";
import RegolamentoPDF from "./../../../../assets/documents/regolamento.pdf";
import FornitoriPDF from "./../../../../assets/documents/fornitori.pdf";

const Header = () => {
  const authContext = useAuthContext();

  return (
    <header id="Header">
      <div className="inner-header">
        <div id="TopBar" className="wow bounceInDown h-100">
          <div className="container-xl h-100">
            <div className="row align-items-center h-100">
              <div className="col-4 col-md-2 h-100">
                <div className="logo-bar h-100">
                  <div className="logo h-100">
                    <Link to="/presentazione" id="logo">
                      <img
                        className="logo-main h-100"
                        src={ImgLogoSVG}
                        alt="SI Supply logo"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-8 col-md-10 right-block align-self-center">
                <div className="menu_wrapper">
                  <input
                    type="checkbox"
                    className="hide-desktop hide-tablet"
                    id="overlay-menu-input"
                  />
                  <label
                    htmlFor="overlay-menu-input"
                    className="hide-desktop hide-tablet"
                    id="overlay-menu-button"
                  >
                    <span></span>
                  </label>
                  <div id="overlay-menu">
                    <div className="menu_box fit-content">
                      <div className="menu_inner">
                        <nav className="navbar navbar-expand-md navbar-light">
                          <div className="" id="navbarNav">
                            <ul className="navbar-nav">
                              <li className="nav-item">
                                <NavLink
                                  to="/presentazione"
                                  className="nav-link"
                                >
                                  <span className="nav-text">Home</span>
                                </NavLink>
                              </li>
                              <li className="nav-item">
                                <NavLink
                                  to={RegolamentoPDF}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="nav-link"
                                >
                                  <span className="nav-text">Regolamento</span>
                                </NavLink>
                              </li>
                              <li className="nav-item">
                                <NavLink
                                  to={FornitoriPDF}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="nav-link"
                                >
                                  <span className="nav-text">Fornitori</span>
                                </NavLink>
                              </li>
                              <li className="nav-item">
                                <NavLink to="/catalogo" className="nav-link">
                                  <span className="nav-text">Premi</span>
                                </NavLink>
                              </li>
                              {authContext.isLoggedIn ? (
                                <li className="nav-item">
                                  <NavLink to="/profilo" className="nav-link">
                                    <span className="nav-text">
                                      Area Personale
                                    </span>
                                  </NavLink>
                                </li>
                              ) : null}
                              {!authContext.isLoggedIn ? (
                                <li className="nav-item">
                                  <NavLink to="/" className="nav-link">
                                    <span className="nav-text">Login</span>
                                  </NavLink>
                                </li>
                              ) : null}
                              {authContext.isLoggedIn ? (
                                <li className="nav-item">
                                  <a
                                    href="/"
                                    disabled
                                    onClick={authContext.logout}
                                    className="nav-link"
                                  >
                                    <span className="nav-text">Logout</span>
                                  </a>
                                </li>
                              ) : null}
                            </ul>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
